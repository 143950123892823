import React, {useState } from 'react'
import {getUser} from '../../../../actions/session'
import Axios from 'axios'
import { SOUND_CREATE_API } from '../../../../config/rest_endpoints'
import Spinner from '../../../../components/common/spinner'
import { Notifications } from '../../../../components/common/notification'

const Add = (props) => {
    const {close, refresh} = props
    const [userInfo] = useState(getUser())
    const [isLoad, setIsLoad] = useState(false)
    const [state, setState] = useState({
        name:''
    })
    const [file, setFile] = useState([])
    const [fileName, setFileName] = useState('')


    const handleChange = (e) => {
        const {name, value} = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleUpload = (e) => {
        setFile(e.target.files[0])
        setFileName(e.target.files[0]['name'])
    }
    
    console.log(`file`, file.name)
    const onSubmit = () => {
        const {token } = userInfo
        console.log(`userInfo`, userInfo)
        setIsLoad(true)
        let body = new FormData()
        body.append('soundFile', file)
        body.append('soundName', JSON.stringify(state.name))
        const headers = { "Authorization": "Bearer " + token };
        Axios.post(`${SOUND_CREATE_API}`, body, { headers }).then(res => {
            if (res.status === 200) {
                setIsLoad(false)
             close()
                Notifications.notify(res.data.message, "Success", "success");
             refresh()
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            let resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    return (
        <div>
            <div className="card card-body">
                <div>
                    <div className="form-group">
                        <label>File Name:</label>
                        <input type="text" className="form-control" name="name" value={state.name} onChange={handleChange} placeholder="File Name" />
                    </div>
                    <div className="form-group">
                        <label>Upload File:</label>
                        <label className="custom-file">
                            <input type="file" className="custom-file-input" onChange={handleUpload} />
                            <span className="custom-file-label">{fileName.length ? fileName : 'Choose file'}</span>
                        </label>
                        <span className="form-text text-muted">
                            Accepted formats: mp3, csv Max file size 2Mb
                        </span>
                    </div>
                   
                </div>
            </div>
            <div className="text-right">
                <button type="submit" className="btn btn-theme-main" onClick={onSubmit} >
                    Create {isLoad ? <Spinner /> : <i className="icon-paperplane ml-2" />}
                </button>
            </div>
        </div>

    )
}

export default Add