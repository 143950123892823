import React, { Fragment, useEffect, useState } from 'react'
import { getUser } from '../../../../actions/session'
import Axios from 'axios'
import {
    CALLERID_LISTING_API,
    CAMPAIGN_CREATE_API,
    CONTACT_LISTING_API, DNC_CAMPAIGN_LISTING_API,
    SOUND_CREATE_API,
    SOUND_LISTING_API
} from '../../../../config/rest_endpoints'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from '../../../../components/common/spinner'
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addMonths from "date-fns/addMonths";
import MultiSelect from "@khanacademy/react-multi-select";
import { Notifications } from '../../../../components/common/notification'

const options = [
    { value: 'pressOne', label: 'PressOne' }
]

const optionsDays = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
]

const Add = (props) => {
    const { close, refresh } = props
    const [userInfo] = useState(getUser())
    const [isLoad, setIsLoad] = useState(false)
    const [state, setState] = useState({
        name: '',
        timeZone: "CET",
        callPerMin: 50,
        callTimeout: 30,
        transferKey: '',
        transferSIPURL: '',
        dncKey: ''
    })

    const [actLim, setActLim] = useState(false)

    // const [timeZone, setTimeZone] = useState('')
    const [type, setType] = useState({ value: 'pressOne', label: 'PressOne' })
    const [day, setDay] = useState([])


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date(startDate.getTime() + 30*60000));

    const [startTime, setStartTime] = useState(setHours(setMinutes(new Date(), 0), 8));
    const [stopTime, setStopTime] = useState(setHours(setMinutes(new Date(), 0), 16));

    const [soundOption, setSoundOption] = useState([])
    const [soundSelect, setSoundSelect] = useState('')
    const [soundSelectTransfer, setSoundSelectTransfer] = useState('')
    const [soundSelectDNC, setSoundSelectDNC] = useState('')


    

    const [contactOption, setContactOption] = useState([])
    const [contactSelect, setContactSelect] = useState('')

    const [callerOption, setcallerOption] = useState([])

    const [dncOption, setdncOption] = useState([])
    const [dncSelect, setdncSelect] = useState('')


    useEffect(() => {
        getSoundList()
        getContactList()
        getCallerList()
        getDNCList()
    }, [])

    const getSoundList = () => {
        const { token } = userInfo
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${SOUND_LISTING_API}`, { headers }).then(res => {
            if (res.status === 200) {
                let resp = res.data
                let list = resp.data.map(row => {
                    row['label'] = row['soundName']
                    row['value'] = row['id']
                    return row
                })
                setSoundOption(list)
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const getContactList = () => {
        const { token } = userInfo
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CONTACT_LISTING_API}`, { headers }).then(res => {
            if (res.status === 200) {
                let resp = res.data
                let list = resp.data.map(row => {
                    row['label'] = row['name']
                    row['value'] = row['id']
                    return row
                })
                setContactOption(list)
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const getCallerList = () => {
        const { token } = userInfo
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CALLERID_LISTING_API}`, { headers }).then(res => {
            if (res.status === 200) {
                let resp = res.data
                let list = resp.data.map(row => {
                    row['label'] = row['name']
                    row['value'] = row['id']
                    return row
                })
                setcallerOption(list)
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }


    const getDNCList = () => {
        const { token } = userInfo
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${DNC_CAMPAIGN_LISTING_API}`, { headers }).then(res => {
            if (res.status === 200) {
                let resp = res.data
                let list = resp.data.map(row => {
                    row['label'] = row['name']
                    row['value'] = row['fileID']
                    return row
                })
                setdncOption(list)
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSelectType = (type) => {
        setType(type)
    }

    const handleSelectDays = (day) => {
        setDay(day)
    }

    const handleChangeAct = (actLim) => {
        setActLim(actLim)
    }


    const handleStartDate = (date) => {
        setStartDate(date)
    }


    const handleEndDate = (date) => {
        setendDate(date)
    }


    const handleSoundTranfer = (soundSelectTransfer) => {
        setSoundSelectTransfer(soundSelectTransfer)
    }

    const handleSoundDNC = (soundSelectDNC) => {
        setSoundSelectDNC(soundSelectDNC)
    }

    const handleSoundFileById = (soundSelect) => {
        setSoundSelect(soundSelect)
    }

    const handleContact = (contactSelect) => {
        setContactSelect(contactSelect)
    }


    const handleDNC = (dncSelect) => {
        setdncSelect(dncSelect)
    }

    const onSubmit = () => {
        const timeStart = startTime.getHours() + ':' + startTime.getMinutes();
        const timeStop = stopTime.getHours() + ':' + stopTime.getMinutes();
        const payload = {
            "type": type.value,
            "name": state.name,
            "callsPerMin": parseInt(state.callPerMin),
            "callTimeout":parseInt(state.callTimeout),
            "contactListID": contactSelect.value,
            "campaignScheduleInfo": {
                "startDate": startDate,
                "stopDate": endDate,
                "dailyStartTime": timeStart,
                "dailyStopTime": timeStop,
                "timeZone": state.timeZone,
                "runningDays": day
            },
            "campaignInfo": {
                "soundFileID": soundSelect.value,
                "dncList": dncSelect.value,
                "dncKey": state.dncKey,
                "transferSoundFileID": soundSelectTransfer.value,
                "transferSIPURL": state.transferSIPURL,
                "transferKey": state.transferKey,
                "dncSoundFileID": soundSelectDNC.value
            }
        }
        const { token } = userInfo
        setIsLoad(true)
        const headers = { "Authorization": "Bearer " + token };
        Axios.post(`${CAMPAIGN_CREATE_API}`, payload, { headers }).then(res => {
            if (res.status === 200) {
                console.log(`res`, res)
                setIsLoad(false)
                Notifications.notify(res.data.message, "Success", "success");
                refresh()
                close()
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            let resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="d-lg-flex">
                        <ul className="nav nav-tabs nav-tabs-vertical flex-column mr-lg-3 wmin-lg-200 mb-lg-0 border-bottom-0">
                            <li className="nav-item"><a href="#vertical-left-tab1" className="nav-link active" data-toggle="tab"><i className="icon-mention mr-2" />General</a></li>
                            <li className="nav-item"><a href="#vertical-left-tab2" className="nav-link" data-toggle="tab"><i className="icon-mention mr-2" />Campaign Schedule</a></li>
                            <li className="nav-item"><a href="#vertical-left-tab3" className="nav-link" data-toggle="tab"><i className="icon-mention mr-2" />Campaign Info</a></li>
                        </ul>
                        <div className="tab-content flex-lg-fill">
                            <div className="tab-pane fade active show" id="vertical-left-tab1">
                                <div className="form-group">
                                    <label>Name :</label>
                                    <input type="text" className="form-control" name="name" value={state.name} onChange={handleChange} placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <label>Type :</label>
                                    <Select
                                        value={type}
                                        onChange={handleSelectType}
                                        options={options}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Contact List :</label>
                                    <Select
                                        value={contactSelect}
                                        onChange={handleContact}
                                        options={contactOption}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>DNC List :</label>
                                    <Select
                                        value={dncSelect}
                                        onChange={handleDNC}
                                        options={dncOption}
                                    />
                                </div>


                                <div className="form-group">
                                    <label>Call Per Min :</label>
                                    <input type="text" className="form-control" name="callPerMin" value={state.callPerMin} onChange={handleChange} placeholder="Call Per Min" />
                                </div>

                                <div className="form-group">
                                    <label>Call Timeout :</label>
                                    <input type="number" className="form-control" name="callTimeout" value={state.callTimeout} onChange={handleChange} placeholder="Call Timeout" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="vertical-left-tab2">
                            <div className="form-group">
                                    <label>Days :</label>
                                    <MultiSelect
                                        options={optionsDays}
                                        selected={day}
                                        onSelectedChanged={handleSelectDays}
                                        overrideStrings={{
                                            selectSomeItems: "Select Some days",
                                        }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Campaign Start Date :</label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleStartDate}
                                                minDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Campaign End Date :</label>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={handleEndDate}
                                                minDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time :</label>
                                            <DatePicker
                                                selected={startTime}
                                                onChange={(date) => setStartTime(date)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Stop Time :</label>
                                            <DatePicker
                                                selected={stopTime}
                                                onChange={(date) => setStopTime(date)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Time zone :</label>
                                    <input type="text" className="form-control" name="timeZone" value={state.timeZone} onChange={handleChange} placeholder="Time zone" />
                                </div>
                             
                            </div>
                            <div className="tab-pane fade" id="vertical-left-tab3">
                                <div className="form-group">
                                    <label>Main Sound File :</label>
                                    <Select
                                        value={soundSelect}
                                        onChange={handleSoundFileById}
                                        options={soundOption}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Transfer Sound File :</label>
                                    <Select
                                        value={soundSelectTransfer}
                                        onChange={handleSoundTranfer}
                                        options={soundOption}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Transfer key :</label>
                                    <input type="Number" className="form-control" name="transferKey" value={state.transferKey} onChange={handleChange} placeholder="Transfer Key"/>
                                </div>

                                <div className="form-group">
                                    <label>Transfer SIPURL :</label>
                                    <input type="text" className="form-control" name="transferSIPURL" value={state.transferSIPURL} onChange={handleChange} placeholder="Transfer SIPURL"  placeholder="e.g. 2123727200@192.168.10.20" />
                                </div>

                                <div className="form-group">
                                    <label>DNC Sound File :</label>
                                    <Select
                                        value={soundSelectDNC}
                                        onChange={handleSoundDNC}
                                        options={soundOption}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>DNC key :</label>
                                    <input type="Number" className="form-control" name="dncKey" value={state.dncKey} onChange={handleChange} placeholder="DNC key" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-right">
                <button type="submit" className="btn btn-theme-main" onClick={onSubmit}>
                    Create {isLoad ? <Spinner /> : <i className="icon-paperplane ml-2" />}
                </button>
            </div>
        </Fragment>
    )
}

export default Add