import React, { Component } from 'react';
import HomeIndex from './pages/customer/home';


class App extends Component {

    render() {
        return (
          <HomeIndex />
        );
    }

}

export default App
