import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Error404 from '../components/common/error404';
import App from '../App';
import {
    RC_CALLER_ID,
    RC_CAMPAIGN,
    RC_CONTACT_LIST,
    RC_DNC_LIST,
    RC_LOAD_APP,
    RC_LOAD_LOGIN,
    RC_LOAD_LOGOUT,
    RC_SOUND,
    RC_CONTACT_LIST_DETAILS
} from './RouteConstants';
import Login from '../pages/authentication/login';
import ProtectedRoute from './ProtectedRoute';
import SoundIndex from '../pages/customer/sound';
import CampaignIndex from '../pages/customer/campaign';
import CallerIDIndex from '../pages/customer/callerId';
import ContactListIndex from '../pages/customer/contactList';
import DNCListIndex from '../pages/customer/dncList';
import Logout from '../components/common/logout';
import contactListDetailsTable from '../pages/customer/contactList/components/contactListDetailsTable';



class AllRoutes extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={RC_LOAD_LOGIN} component={Login} />
                    <Route exact path={RC_LOAD_LOGOUT} component={Logout} />
                    <ProtectedRoute exact path={RC_LOAD_APP} component={App} />
                    <ProtectedRoute exact path={RC_SOUND} component={SoundIndex} />
                    <ProtectedRoute exact path={RC_CAMPAIGN} component={CampaignIndex} />
                    <ProtectedRoute exact path={RC_CALLER_ID} component={CallerIDIndex} />
                    <ProtectedRoute exact path={RC_CONTACT_LIST} component={ContactListIndex} />
                    <ProtectedRoute exact path={`${RC_CONTACT_LIST_DETAILS}/:id`} component={contactListDetailsTable} />
                    <ProtectedRoute exact path={RC_DNC_LIST} component={DNCListIndex} />
                    <Route path="*" component={Error404} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default AllRoutes