const PROTOCOL = 'https';
const SERVER_IP = 'dialer.campagnetool.app';
const PORT = '8080';
// http://44.200.93.113:3000/contact-list

export const SERVER_PATH = `${PROTOCOL}://${SERVER_IP}:${PORT}`;
export const ROOT_URL = `${SERVER_PATH}/v1`;


export const LOGIN_URL = `${ROOT_URL}/login`

export const SOUND_LISTING_API = `${ROOT_URL}/sound`
export const SOUND_CREATE_API = `${ROOT_URL}/sound`

export const CALLERID_LISTING_API = `${ROOT_URL}/callerid`
export const CALLERID_CREATE_API = `${ROOT_URL}/callerid`

export const CONTACT_LISTING_API = `${ROOT_URL}/contactlist`
export const CONTACT_CREATE_API = `${ROOT_URL}/contactlist`

export const DNC_LISTING_API = `${ROOT_URL}/dnclist`
export const DNC_CAMPAIGN_LISTING_API = `${ROOT_URL}/campaign/dnclist`
export const DNC_CREATE_API = `${ROOT_URL}/dnclist`

export const CAMPAIGN_LIST_API = `${ROOT_URL}/campaign`
export const CAMPAIGN_CREATE_API = `${ROOT_URL}/campaign`
export const CAMPAIGN_START_API = `${ROOT_URL}/campaign`
export const CAMPAIGN_STOP_API = `${ROOT_URL}/campaign`

export const HOME_DASHBOARD_STATS_API = `${ROOT_URL}/account/dashboard`


