import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { getUser, hanldeUnauthorized } from '../../../../actions/session'
import { HOME_DASHBOARD_STATS_API } from '../../../../config/rest_endpoints'
import { RC_LOAD_LOGIN, RC_LOAD_LOGOUT } from '../../../../routers/RouteConstants'
import { Notifications } from '../../../../components/common/notification'

const HomeCardStats = (props) => {
    const [userInfo] = useState(getUser())
    const [data, setData] = useState({})
    const [isLoad, setIsLoad] = useState(false)

    useEffect(() => {
        getStatsData()
    }, [])

    const getStatsData = () => {
        const { token } = userInfo
        setIsLoad(true)
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${HOME_DASHBOARD_STATS_API}`, { headers }).then(res => {
            if (res.status === 200) {
                let resp = res.data
                setData(resp.data)
                setIsLoad(false)
            }
        }).catch(err => {
            const statusCode = err.response.status
            if (statusCode === 401) {
                localStorage.removeItem('user')
                props.history.push(RC_LOAD_LOGIN)
            }
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");
        })

    }

    return (
        <div className="row">
            <div className="col-sm-6 col-xl-4">
                <div className="card card-body bg-success text-white has-bg-image">
                    <div className="media">
                        <div className="media-body">
                            <h3 className="mb-0">{data.dialedCalls}</h3>
                            <span className="text-uppercase fs-15">Dialing Calls</span>
                        </div>

                        <div className="ml-3 align-self-center">
                            <i className="icon-station icon-3x opacity-75"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-xl-4">
                <div className="card card-body bg-info text-white has-bg-image">
                    <div className="media">
                        <div className="media-body">
                            <h3 className="mb-0">{data.connectedCalls}</h3>
                            <span className="text-uppercase fs-15">Connected Calls</span>
                        </div>

                        <div className="ml-3 align-self-center">
                            <i className="icon-connection icon-3x opacity-75"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-6 col-xl-4">
                <div className="card card-body bg-danger text-white has-bg-image">
                    <div className="media">
                        <div className="media-body">
                            <h3 className="mb-0">{data.transferredCalls}</h3>
                            <span className="text-uppercase fs-15">Transferred Calls</span>
                        </div>
                        <div className="mr-3 align-self-center">
                            <i className="icon-feed icon-3x opacity-75"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(HomeCardStats)