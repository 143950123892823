import React from 'react'
import Container from '../../../components/common/application_container';
import HomeCardStats from './components/card';

const HomeIndex = () => {

    return (
        <Container title="Home" subTitle="Dashboard" icon="icon-home" >
            <HomeCardStats />
        </Container>

    )
}

export default HomeIndex