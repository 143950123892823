export const RC_LOAD_APP = "/";

// auth bash constans
export const RC_AUTH_BASE_URL = "/auth";
export const RC_LOAD_LOGIN = `${RC_AUTH_BASE_URL}/login`;
export const RC_LOAD_LOGOUT = `${RC_AUTH_BASE_URL}/logout`;

export const RC_ERROR_404 = "/error404";

export const RC_SOUND = '/sounds'
export const RC_CAMPAIGN = '/campaign'
export const RC_CALLER_ID = '/caller-id'
export const RC_CONTACT_LIST = '/contact-list'
export const RC_CONTACT_LIST_DETAILS = `${RC_CONTACT_LIST}/contact-details`
export const RC_DNC_LIST = '/dnc-list'

