import React, { useEffect, useState } from 'react'
import {getUser} from '../../../../actions/session'
import Axios from 'axios'
import {CONTACT_LISTING_API} from '../../../../config/rest_endpoints'
import CustomModal from '../../../../components/common/modal'
import CustomLoader from '../../../../components/common/loader'
import Add from './add'
import { withRouter } from 'react-router'
import { RC_LOAD_LOGIN } from '../../../../routers/RouteConstants'
import { Notifications } from '../../../../components/common/notification'
import Container from '../../../../components/common/application_container';


const ContactListDetailsTable = (props) => {
    console.log('props :>> ', props);
    const {id}=props.match.params
    const URLID = id
    console.log('id :>> ', id);
    const [userInfo] = useState(getUser())
    const [items, setItems] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isModal, setIsModal] = useState(false)


    useEffect(()=> {
        getLsiting()
    },[])

 

    const getLsiting = () => {
        const {id,token} = userInfo
        console.log(`userInfo`, userInfo)
        setIsLoad(true)
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CONTACT_LISTING_API}/${URLID}`, {headers}).then(res => {
            if(res.status === 200) {
                let resp = res.data.data.contacts || []
                setItems(resp)
                setIsLoad(false)
            }
        }).catch (err => {
            const statusCode = err.response.status
            if (statusCode === 401) {
                localStorage.removeItem('user')
                props.history.push(RC_LOAD_LOGIN)
            }
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const renderTable = () => {
        if (!items.length) {
            return (
                <div className=" ml-3">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                </div>
            )
        }
        else {
            return items.map((row, idx) => {
                return (
                    <tr key={idx}>
                        <td>{row['caller']}</td>
                        <td>{row['callee']}</td>
                        <td>{row['hangupTime']}</td>
                        <td>{row['providerIP']}</td>
                    </tr>
                )
            })
        }
    }

    return (
        <Container title="Contact List" subTitle="Module" icon="icon-address-book" >
       
        <div className="card">
        <div className="card-header">
            </div>
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="datatable-header">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                        <label>
                            <span>Filter:</span>{" "}
                            <input
                                type="search"
                                className=""
                                placeholder="Type to filter..."
                                aria-controls="DataTables_Table_0"
                            />
                        </label>
                    </div>
                </div>
                <div className="datatable-scroll">
                    <table
                        className="table datatable-basic dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info">
                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>HangupTime</th>
                                <th>ProviderIP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoad ?
                                <tr>
                                    <td colSpan="100%">
                                        <CustomLoader />
                                    </td>
                                </tr> : renderTable()}
                        </tbody>
                    </table>
                </div>
                <div className="datatable-footer">

                </div>
            </div>
        </div>
        </Container>

    )
}

export default withRouter(ContactListDetailsTable) 