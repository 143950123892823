import { DATA_KEY } from './globalKeys';
import { getUser } from './session';
export const ACTIVE_USER = "save_user";



/**
 * Validate the user
 * ----------------------
 * 
 * @param {*} userDeatils : {user information}
 */
export function login(data) {
    return function (dispatch) {
        let res = { [DATA_KEY]: data }
        dispatch({
            type: ACTIVE_USER,
            payload: res
        })
    }
}



/**
 *  Get the user Details
 * ------------------------
 * 
 */
export function fetchUser() {
    return function (dispatch) {
        let res = getUser()
        res = { [DATA_KEY]: res }

        dispatch({
            type: ACTIVE_USER,
            payload: res
        })
    }
}