import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter, Redirect } from 'react-router-dom'
import { login } from '../../actions/index';
import { Notifications } from '../../components/common/notification';
import { RC_CALLER_ID, RC_CAMPAIGN, RC_CONTACT_LIST, RC_CONTACT_LIST_DETAILS, RC_DNC_LIST, RC_LOAD_APP, RC_SOUND } from '../../routers/RouteConstants';
import { saveUser, getUser } from '../../actions/session';
import { isNonEmptyDict } from '../../actions/validator';
import Axios from 'axios';
import { LOGIN_URL } from '../../config/rest_endpoints';
import Spinner from '../../components/common/spinner';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDetails: { username: "", password: "" },
            isLoad:false
        }

        this.chkUser = this.chkUser.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleValue = this.handleValue.bind(this)
    }

    componentDidMount() {
        this.chkUser();
    }

    componentWillReceiveProps(newProps) {
        let { activeUser } = newProps;
        if (isNonEmptyDict(activeUser)) {
            saveUser(activeUser)
            this.props.history.push(RC_LOAD_APP)
            Notifications.notify("Login Successfully", "Login", "success");
        }

    }

    chkUser() {
        let current_user = getUser()
        if (current_user) {
            this.props.history.push(RC_LOAD_APP);
        }
    }

    handleValue(e) {
        let { name, value } = e.target;
        let { userDetails } = this.state;
        userDetails[name] = value

        this.setState({ userDetails })
    }

    clearForm() {
        let userDetails = { username: "", password: "" }
        this.setState({ userDetails })
    }

    onLogin() {
        let { userDetails } = this.state;
        let header = { headers: { 'Content-Type': 'application/json' } }
        let body = {
            email: userDetails.username,
            password: userDetails.password
        }
        this.setState({isLoad:true})
        Axios.post(LOGIN_URL, body, header).then(res => {
            let token = res.headers['x_auth_token']
            if (res.status === 200) {
                let response = res
                let data = {
                    ...response.data.data,
                    "permissions": `${RC_LOAD_APP},${RC_SOUND},${RC_CAMPAIGN},${RC_CONTACT_LIST},${RC_DNC_LIST},${RC_CONTACT_LIST_DETAILS}`,
                    "token": token
                }
                this.setState({isLoad:false})
                Notifications.notify(res.data.message, "Success", "success");
                this.props.login(data);
            }
        
        }).catch(err => {
           console.log(`err`, err)
            let resp = err.response.data
           this.setState({isLoad:false})
            Notifications.notify(resp['message'], "error", "error");
        })
    }

    render() {
        const { userDetails, isLoad } = this.state
        let { username, password } = userDetails

        return (
            <div className="content" style={{ padding: '9.25rem 1.25rem' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="/assets/images/undraw_remotely_2j6y.svg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-md-6 contents">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="mb-4">
                                        <h3>Sign In</h3>
                                    </div>
                                    <div>
                                        <div className="form-group first">
                                            <label htmlFor="username">Username</label>
                                            <input type="text" className="form-control" name="username" value={username} onChange={this.handleValue} id="username" />
                                        </div>
                                        <div className="form-group last mb-4">
                                            <label htmlFor="password">Password</label>
                                            <input type="password" className="form-control" name="password" value={password} onChange={this.handleValue} id="password" />
                                        </div>
                                        <button type="submit" defaultValue="Log In" onClick={this.onLogin} className="btn btn-block btn-primary" >Login {isLoad ? <Spinner/> : <i class="icon-enter ml-2 icon-1x"></i>}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ activeUser }) {
    return { activeUser }
}

export default connect(mapStateToProps, { login })(withRouter(Login));