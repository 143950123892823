import React from 'react'
import Container from '../../../components/common/application_container';
import Table from './components/table';

const CampaignIndex = () => {

    return (
        <Container title="Campaign" subTitle="Module" icon="icon-satellite-dish2" >
            <Table />
        </Container>

    )
}

export default CampaignIndex