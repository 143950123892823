import React from 'react'
import Container from '../../../components/common/application_container';
import Table from './components/table';

const ContactListIndex = () => {

    return (
        <Container title="Contact List" subTitle="Module" icon="icon-address-book" >
          <Table />
        </Container>
       
    )
}

export default ContactListIndex