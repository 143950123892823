import React, { useEffect, useState } from 'react'
import {getUser} from '../../../../actions/session'
import Axios from 'axios'
import { CAMPAIGN_LIST_API,CAMPAIGN_START_API,CAMPAIGN_STOP_API } from '../../../../config/rest_endpoints'
import CustomModal from '../../../../components/common/modal'
import CustomLoader from '../../../../components/common/loader'
import Add from './add'
import { RC_LOAD_LOGIN } from '../../../../routers/RouteConstants'
import { withRouter } from 'react-router'
import { Notifications } from '../../../../components/common/notification'
import Edit from './edit'

const Table = (props) => {
    const [userInfo] = useState(getUser())
    const [items, setItems] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [isStart, setIsStart] = useState(false)
    const [dataId, setDataId] = useState({})

    const [campaignInfo, setCampaignInfo] = useState({})

    const [isEdit, setIsEdit] = useState(false)


    useEffect(()=> {
        getLsiting()
    },[])

    const modalOpen = () => {
        setIsModal(true)
    }

    const editModalOpen = (data) => {
        setIsEdit(true)
        setCampaignInfo(data)
    }


    const modalClose = () => {
        setIsModal(false)
        setIsEdit(false)
    }

    const getLsiting = () => {
        const {id,token} = userInfo
        setIsLoad(true)
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CAMPAIGN_LIST_API}`, {headers}).then(res => {
            if(res.status === 200) {
                console.log(`res`, res)
                let resp = res.data
                setItems(resp.data)
                setIsLoad(false)
            }
        }).catch (err => {
            const statusCode = err.response.status
            if (statusCode === 401) {
                localStorage.removeItem('user')
                props.history.push(RC_LOAD_LOGIN)
            }
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const startCampaign = (data) => {
        setDataId(data)
        const {id,token} = userInfo
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CAMPAIGN_START_API}/${data.id}/action/start`, {headers}).then(res => {
            if(res.status === 200) {
                console.log(`res`, res)
                let resp = res.data
                setIsStart(true)
                getLsiting()
            }
        }).catch (err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const stopCampaign = (data) => {
        const {id,token} = userInfo
        console.log(`userInfo`, userInfo)
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CAMPAIGN_STOP_API}/${data.id}/action/stop`, {headers}).then(res => {
            if(res.status === 200) {
                console.log(`res`, res)
                let resp = res.data
                setIsStart(false)
                getLsiting()
            }
        }).catch (err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const pauseCampaign = (data) => {
        const {id,token} = userInfo
        console.log(`userInfo`, userInfo)
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${CAMPAIGN_STOP_API}/${data.id}/action/pause`, {headers}).then(res => {
            if(res.status === 200) {
                console.log(`res`, res)
                let resp = res.data
                setIsStart(false)
                getLsiting()
            }
        }).catch (err => {
            console.log(`err`, err)
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const renderTable = () => {
        const dropDownStyle = {position: 'absolute', transform: 'translate3d(558px, 272px, 0px)', top: '0px', left: '0px', willChange: 'transform'}
        if (!items.length) {
            return (
                <div className=" ml-3">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                </div>
            )
        }
        else {
            return items.map((row, idx) => {
                const progress = (row['campaignStats']['dialed'] / row['campaignStats']['total'] * 100).toFixed(2)
                return (
                    <tr key={idx}>

                        <td >
                            <span><b>Name:</b> {row.name }</span><br />
                            <span><b>Contact List:</b> {row['campaignInfo']['contactListName']}</span><br />
                            <span><b>CallerID List:</b> {row['campaignInfo']['callerIDListName']}</span><br />
                            <span><b>Calls Per Min:</b> {row['callsPerMin']}</span><br />
                        </td>


                        <td >
                            <span><b>Dialing Calls:</b> {row['campaignStats']['liveDialedCalls']}</span><br />
                            <span><b>Connected Calls:</b> {row['campaignStats']['liveConnectedCalls']}</span><br />
                            <span><b>Transferred Calls:</b> {row['campaignStats']['liveTransferredCalls']}</span><br />
                        </td>


                        <td>
                            <li className="mb-3" style={{listStyle: 'none'}}>
                                <div className="d-flex align-items-center mb-1"><span
                                    className="text-muted">{row.status}</span> <span
                                    className="text-muted ml-auto">{progress}%</span></div>
                                <div className="progress" style={{height: '0.5rem'}}>
                                    <div className="progress-bar  bg-theme-color progress-bar-striped"
                                         style={{width: `${progress}%`}} aria-valuemax="100">
                                        <span className="sr-only">{progress}%</span>
                                    </div>
                                </div>
                            </li>
                            {/* <div class="progress">
                                <div class="progress-bar bg-theme-color progress-bar-striped" role="progressbar" style={{ width: `${progress}%` }} aria-valuemax="100"><p>{progress}%</p></div>
                            </div> */}


                        </td>

                        <td >
                            <span><b>Machine Percentage:</b> {row['campaignStats']['machinePercentage']}</span><br />
                            <span><b>Human Percentage:</b> {row['campaignStats']['humanPercentage']}</span><br />
                            <span><b>Not Sure Percentage:</b> {row['campaignStats']['notsurePercentage']}</span><br />
                        </td>


                        <td >
                            <span><b>ACD:</b> {row['campaignStats']['ACD']}</span><br />
                            <span><b>ASR:</b> {row['campaignStats']['ASR']}</span><br />
                            <span><b>Connected:</b> {row['campaignStats']['connected']}</span><br />
                            <span><b>Transferred:</b> {row['campaignStats']['transfer']}</span><br />
                            <span><b>Failed:</b> {row['campaignStats']['failed']}</span><br />
                            <span><b>Dialed:</b> {row['campaignStats']['dialed']}</span><br />
                            <span><b>Total:</b> {row['campaignStats']['total']}</span><br />
                        </td>

                        <td>
                            {row.status === "Finished" ?
                                <>
                                    <button type="button" className="btn btn-outline-primary btn-icon mr-2" disabled={true}
                                            onClick={startCampaign.bind(this, row)}
                                            style={{padding: '.4375rem 0.875rem'}}>
                                        <i className="icon-play4"></i>
                                    </button>
                                    <button type="button" className="btn btn-outline-primary btn-icon" disabled={true}
                                            onClick={stopCampaign.bind(this, row)}
                                            style={{padding: '.4375rem 0.875rem'}}><i className="icon-stop2"></i>
                                    </button>
                                </>

                                : row.status === "Stopped" ?
                                    <>
                                        <button type="button" className="btn btn-outline-primary btn-icon mr-2"
                                                 onClick={startCampaign.bind(this, row)}
                                                style={{padding: '.4375rem 0.875rem'}}>
                                            {row.id === dataId.id && isStart ? <i className="icon-pause2"></i> :
                                                <i className="icon-play4"></i>}
                                        </button>
                                        <button type="button" className="btn btn-outline-primary btn-icon" disabled={true}
                                                onClick={stopCampaign.bind(this, row)}
                                                style={{padding: '.4375rem 0.875rem', backgroundColor: '#5b6782'}}><i className="icon-stop2" style={{color:'white'}}></i>
                                        </button>
                                    </>
                                    : row.status === "Paused" ?
                                    <>
                                        <button type="button" className="btn btn-outline-primary btn-icon mr-2"
                                                onClick={startCampaign.bind(this, row)}
                                                style={{padding: '.4375rem 0.875rem'}}>
                                            {row.id === dataId.id && isStart ? <i className="icon-pause2"></i> :
                                                <i className="icon-play4"></i>}
                                        </button>
                                        <button type="button" className="btn btn-outline-primary btn-icon"
                                                onClick={stopCampaign.bind(this, row)}
                                                style={{padding: '.4375rem 0.875rem',}}><i className="icon-stop2"></i>
                                        </button>
                                    </>
                                    : row.status === "Running" ?
                                <>

                                    <button type="button" className="btn btn-outline-primary btn-icon mr-2"
                                            onClick={pauseCampaign.bind(this, row)}
                                            style={{padding: '.4375rem 0.875rem'}}>
                                        {row.id === dataId.id && isStart ? <i className="icon-pause2"></i> :
                                            <i className="icon-pause2"></i>}
                                    </button>

                                <button type="button" className="btn btn-outline-primary btn-icon"
                                onClick={stopCampaign.bind(this, row)}
                                style={{padding: '.4375rem 0.875rem',}}><i className="icon-stop2"></i>
                                </button>
                                </>
                                :
                                            <>
                                    <button type="button" className="btn btn-outline-primary btn-icon mr-2"
                                            onClick={startCampaign.bind(this, row)}
                                            style={{padding: '.4375rem 0.875rem'}}>
                                        {row.id === dataId.id && isStart ? <i className="icon-pause2"></i> :
                                            <i className="icon-play4"></i>}
                                    </button>
                                    <button type="button" className="btn btn-outline-primary btn-icon"
                                            onClick={stopCampaign.bind(this, row)}
                                            style={{padding: '.4375rem 0.875rem'}}><i className="icon-stop2"></i>
                                    </button>
                                </>

                            }

                        </td>

                        <td>
                            <button type="button"
                                className="btn btn-outline btn-icon"
                                onClick={editModalOpen.bind(this, row)}><i className="icon-menu7"></i>
                            </button>
                        </td>
                    </tr>
                )
            })
        }
    }

    let uploadModal = {
        details: {
            info: `Create Campaign`,
            msg: <Add close={modalClose} refresh={getLsiting} />
        },
        confirmBtn: { label: "Update", action: '' },
        crossBtn: { action: modalClose },
        cancelBtn: { label: "Cancel", action: modalClose }
    }

    let editModal = {
        details: {
            info: `Edit Campaign`,
            msg: <Edit info={campaignInfo} close={modalClose} refresh={getLsiting} />
        },
        confirmBtn: { label: "Update", action: '' },
        crossBtn: { action: modalClose },
        cancelBtn: { label: "Cancel", action: modalClose }
    }

    return (
        <div className="card" style={{overflowX: 'auto'}}>
            <CustomModal
                visibility={isModal}
                customBodyCSS={"h20"}
                modalType={"success"}
                modalSize="large"
                details={uploadModal.details}
                showBtns={false}
                cancelBtn={uploadModal.cancelBtn}
                crossBtn={uploadModal.crossBtn}
            />
              <CustomModal
                visibility={isEdit}
                customBodyCSS={"h20"}
                modalType={"success"}
                modalSize="large"
                details={editModal.details}
                showBtns={false}
                cancelBtn={editModal.cancelBtn}
                crossBtn={editModal.crossBtn}
            />
            <div className="card-header">
                <button type="button" className="btn btn-theme-main float-right" onClick={modalOpen}>Create Campaign<i className="icon-pencil5 ml-2"></i> </button>
            </div>
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="datatable-header">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                        <label>
                            <span>Filter:</span>{" "}
                            <input
                                type="search"
                                className=""
                                placeholder="Type to filter..."
                                aria-controls="DataTables_Table_0"
                            />
                        </label>
                    </div>
                </div>
                <div className="datatable-scroll">
                    <table
                        className="table datatable-basic dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info">
                        <thead>
                        <tr>
                            <th>General Info</th>
                            <th>Live Calls Stats</th>
                            <th>Progress</th>
                            <th>AMD Stats</th>
                            <th>Campaign Stats</th>
                            <th>Action</th>
                            <th>Edit</th>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoad ?
                            <tr>
                                <td colSpan="100%">
                                    <CustomLoader />
                                </td>
                            </tr> : renderTable()}
                        </tbody>
                    </table>
                </div>
                <div className="datatable-footer">

                </div>
            </div>
        </div>

    )
}

export default withRouter(Table)