import React, { useState } from 'react'
import {getUser} from '../../../../actions/session'
import Axios from 'axios'
import {DNC_CREATE_API,} from '../../../../config/rest_endpoints'
import Select from 'react-select';
import Spinner from '../../../../components/common/spinner';
import { Notifications } from '../../../../components/common/notification'


const Add = (props) => {
    const {close, refresh} = props
    const [userInfo] = useState(getUser())
    const [isLoad, setIsLoad] = useState(false)
    const [state, setState] = useState({
        name:''
    })
    const [file, setFile] = useState([])
    const [fileName, setFileName] = useState('')
    const [fileResult, setFileResult] = useState([])

    const [headersOption, setHeadersOption] = useState([])
    const [selectHeader, setSelectHeader] = useState('')

    const [addToGlobalDNC, setAddToGlobalDNC] = useState(true)

    const handleChange = (e) => {
        const {name, value} = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleUpload = (e) => {
        setFile(e.target.files[0])
        setFileName(e.target.files[0]['name'])
        const temp = e.target.files[0]
        fileReader(temp)
    }

    const handleHeaderSelect = (selectHeader) => {
        setSelectHeader(selectHeader)
    }

    const updateAddToGlobalDNC = () => {
        setAddToGlobalDNC(addToGlobalDNC => !addToGlobalDNC)
    }


    const fileReader = (data) => {
        let csvFile = data
        const reader = new FileReader()
        reader.onload = function(e) {
            const text = e.target.result
            let jsonConvert = JSON.stringify(text).split('\\')[0]
            let createArr = jsonConvert.replaceAll('"','').split(',')
            let createoptions = createArr.map((row,idx) => {
                let obj = {
                    label : row,
                    value : row,
                    key : idx
                }
                return obj
            })
            setHeadersOption(createoptions)
            setFileResult(JSON.stringify(text))
        }
        reader.readAsText(csvFile)

    }
    
    console.log(`fileResult`, fileResult)
    const onSubmit = () => {
        const {token } = userInfo
        setIsLoad(true)
        let body = new FormData()
        body.append('dncListFile', file)
        body.append('dncFileName', JSON.stringify(state.name))
        body.append('dncHeader', JSON.stringify(selectHeader.value))
        body.append('addToGlobalDNC', JSON.stringify(addToGlobalDNC))
        const headers = { "Authorization": "Bearer " + token };
        Axios.post(`${DNC_CREATE_API}`, body, { headers }).then(res => {
            if (res.status === 200) {
             close()
                Notifications.notify(res.data.message, "Success", "success");
             refresh()
             setIsLoad(false)
            }
        }).catch(err => {
            console.log(`err`, err)
            setIsLoad(false)
            let resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");
        })
    }

    return (
        <div>
            <div className="card card-body">
                <div>
                    <div className="form-group">
                        <label>DNC File Name:</label>
                        <input type="text" className="form-control" name="name" value={state.name} onChange={handleChange} placeholder="File Name" />
                    </div>
                    <div className="form-group">
                        <label>Upload File:</label>
                        <label className="custom-file">
                            <input type="file" className="custom-file-input" accept='.csv' onChange={handleUpload} />
                            <span className="custom-file-label">{fileName.length ? fileName : 'Choose file'}</span>
                        </label>
                        <span className="form-text text-muted">
                            Accepted formats: csv Max file size 2Mb
                        </span>
                    </div>
                    <div className="form-group">
                        <label>Headers</label>
                        <Select
                            value={selectHeader}
                            onChange={handleHeaderSelect}
                            options={headersOption}
                            isDisabled={headersOption.length ? false : true}
                        />
                    </div>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label className='c-mt1'> Also Add to Global DNC List</label>
                            <input type="checkbox" checked={addToGlobalDNC} onChange={updateAddToGlobalDNC}
                                   className="form-check-input ml-2" id="exampleCheck1"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-right">
                <button type="submit" className="btn btn-theme-main" onClick={onSubmit} >
                    Create {isLoad ? <Spinner /> : <i className="icon-paperplane ml-2" />}
                </button>
            </div>
        </div>

    )
}

export default Add