import React, { useEffect, useState } from 'react'
import {getUser} from '../../../../actions/session'
import Axios from 'axios'
import {DNC_LISTING_API} from '../../../../config/rest_endpoints'
import CustomModal from '../../../../components/common/modal'
import CustomLoader from '../../../../components/common/loader'
import Add from './add'
import { withRouter } from 'react-router'
import { RC_LOAD_LOGIN } from '../../../../routers/RouteConstants'
import { Notifications } from '../../../../components/common/notification'

const Table = (props) => {
    const [userInfo] = useState(getUser())
    const [items, setItems] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isModal, setIsModal] = useState(false)


    useEffect(()=> {
        getLsiting()
    },[])

    const modalOpen = () => {
        setIsModal(true)
    }

    const modalClose = () => {
        setIsModal(false)
    }

    const getLsiting = () => {
        const {id,token} = userInfo
        console.log(`userInfo`, userInfo)
        setIsLoad(true)
        const headers = { "Authorization": "Bearer " + token };
        Axios.get(`${DNC_LISTING_API}`, {headers}).then(res => {
            if(res.status === 200) {
                let resp = res.data
                setItems(resp.data)
                setIsLoad(false)
            }
        }).catch (err => {
            const statusCode = err.response.status
            if (statusCode === 401) {
                localStorage.removeItem('user')
                props.history.push(RC_LOAD_LOGIN)
            }
            setIsLoad(false)
            var resp = err.response.data
            Notifications.notify(resp['message'], "error", "error");

        })
    }

    const renderTable = () => {
        if (!items.length) {
            return (
                <div className=" ml-3">
                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 of 0</div>
                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"></div>
                </div>
            )
        }
        else {
            return items.map((row, idx) => {
                return (
                    <tr key={idx}>
                        <td>{row['id']}</td>
                        <td>{row['name']}</td>
                        <td>{row['fileName']}</td>
                        <td>{row['dncNumberCount']}</td>
                        <td>{row['createDate']}</td>
                        <td>{row['updateDate']}</td>
                    </tr>
                )
            })
        }
    }

    let uploadModal = {
        details: {
            info: `Add Contact`,
            msg: <Add close={modalClose} refresh={getLsiting} />
        },
        confirmBtn: { label: "Update", action: '' },
        crossBtn: { action: modalClose },
        cancelBtn: { label: "Cancel", action: modalClose }
    }

    return (
        <div className="card">
            <CustomModal
                visibility={isModal}
                customBodyCSS={"h20"}
                modalType={"success"}
                modalSize="large"
                details={uploadModal.details}
                showBtns={false}
                cancelBtn={uploadModal.cancelBtn}
                crossBtn={uploadModal.crossBtn}
            />
            <div className="card-header">
                <button type="button" class="btn btn-theme-main float-right" onClick={modalOpen}>Add Contact<i class="icon-pencil5 ml-2"></i> </button>
            </div>
            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
                <div className="datatable-header">
                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                        <label>
                            <span>Filter:</span>{" "}
                            <input
                                type="search"
                                className=""
                                placeholder="Type to filter..."
                                aria-controls="DataTables_Table_0"
                            />
                        </label>
                    </div>
                </div>
                <div className="datatable-scroll">
                    <table
                        className="table datatable-basic dataTable no-footer"
                        id="DataTables_Table_0"
                        role="grid"
                        aria-describedby="DataTables_Table_0_info">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>File Name</th>
                                <th>DNC Count</th>
                                <th>Create Date</th>
                                <th>Update Date</th>

                            </tr>
                        </thead>
                        <tbody>
                            {isLoad ?
                                <tr>
                                    <td colSpan="100%">
                                        <CustomLoader />
                                    </td>
                                </tr> : renderTable()}
                        </tbody>
                    </table>
                </div>
                <div className="datatable-footer">

                </div>
            </div>
        </div>

    )
}

export default withRouter(Table) 