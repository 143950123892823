import React from 'react';
import Loader from 'react-loader-spinner'

const CustomLoader = (props) => {
    return (
        <div className="container" style={props.customstyles} >
            <Loader
                type={props.type}
                color={props.color}
                height={props.width}
                width={props.height}
            />
        </div>

    );
}

export default CustomLoader;

CustomLoader.defaultProps = {
    customstyles: { "position": "relative", left:'40%', "marginTop":'2em' },
    width: 50,
    height: 50,
    color: "#5b6782",
    type: "TailSpin"
}