import {
    RC_CALLER_ID,
    RC_CAMPAIGN,
    RC_CONTACT_LIST,
    RC_DNC_LIST,
    RC_LOAD_APP,
    RC_SOUND
} from '../../routers/RouteConstants';


export var naver = {
    [RC_LOAD_APP]: {
        "/": {
            "id": "M_HOME",
            "name": "Home",
            "url": RC_LOAD_APP,
            "fa_icon": "fa fa-home"
        },
        "fa_icon": "fa fa-home"
    },
    [RC_CAMPAIGN]: {
        "/": {
            "id": "RC_CAMPAIGN",
            "name": "Campaign",
            "url": RC_CAMPAIGN,
            "fa_icon": "icon-satellite-dish2"
        }
    },
    [RC_SOUND]: {
        "/": {
            "id": "RC_SOUND",
            "name": "Sounds",
            "url": RC_SOUND,
            "fa_icon": "icon-mic2"
        }
    },
    [RC_CALLER_ID]: {
        "/": {
            "id": "RC_CALLER_ID",
            "name": "Caller ID",
            "url": RC_CALLER_ID,
            "fa_icon": "icon-address-book3"
        }
    },
    [RC_CONTACT_LIST]: {
        "/": {
            "id": "RC_CONTACT_LIST",
            "name": "Contact List",
            "url": RC_CONTACT_LIST,
            "fa_icon": "icon-address-book"
        }
    },
    [RC_DNC_LIST]: {
    "/": {
        "id": "RC_DNC_LIST",
            "name": "DNC List",
            "url": RC_DNC_LIST,
            "fa_icon": "icon-phone-slash"
    }
}
}

